import {
  HTMLCustomVariantsAwareProps,
  createComponent,
  useTailwindVariants,
} from '@/ui/system';

import { cn } from '@/lib/utils';

import { Flex } from '../flex';
import { type SeparatorVariants, separatorVariants } from './separator.cx';

export interface SeparatorProps
  extends HTMLCustomVariantsAwareProps<'hr', SeparatorVariants> {}

export const Separator = createComponent<SeparatorProps>(
  ({ as: Component = 'hr', children, direction = 'row', ...props }, ref) => {
    const blueprint = useTailwindVariants(
      { direction, ...props },
      separatorVariants,
    );

    const component = <Component ref={ref} {...blueprint.ownProps} />;

    // In case of children, the component needs to render two separators on either side of the children
    if (children) {
      return (
        <Flex
          align="center"
          gap="units-unit8"
          direction={direction}
          className={cn({
            'w-full': direction === 'row',
            'h-full': direction === 'column',
          })}
        >
          {component}
          <Flex noShrink>{children}</Flex>
          {component}
        </Flex>
      );
    }

    return component;
  },
);

Separator.displayName = 'Separator';
