import { cn } from '@/lib/utils';

export function AppleLogo({
  className,
  ...props
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 25 24"
      aria-label={props['aria-label'] ?? 'Apple Logo'}
      xmlns="http://www.w3.org/2000/svg"
      className={cn('inline-block scale-[1.1]', className)}
    >
      <path
        d="M17.219 2.05078C17.2262 2.12027 17.2347 2.2175 17.241 2.33634C17.2535 2.5727 17.2575 2.90132 17.2229 3.27102C17.1563 3.98291 16.9342 4.98119 16.2077 5.70768C15.4812 6.43416 14.4829 6.65633 13.771 6.72293C13.4013 6.75752 13.0727 6.75345 12.8363 6.74096C12.7175 6.73468 12.6203 6.72617 12.5508 6.71896L12.4308 6.70459C12.1023 6.65683 11.8432 6.3982 11.7955 6.06967L11.781 5.94922C11.7738 5.87973 11.7653 5.7825 11.759 5.66367C11.7466 5.4273 11.7425 5.09868 11.7771 4.72898C11.8437 4.01709 12.0658 3.01881 12.7923 2.29232C13.5188 1.56584 14.5171 1.34367 15.229 1.27707C15.5987 1.24248 15.9273 1.24655 16.1637 1.25904C16.2825 1.26532 16.3797 1.27383 16.4492 1.28104L16.5692 1.29542C16.8977 1.34317 17.1568 1.6018 17.2045 1.93033L17.219 2.05078Z"
        fill="currentColor"
      />
      <path
        d="M5.27858 9.09078C6.2609 7.88966 7.63419 7.25 9.14044 7.25C10.1438 7.25 11.0261 7.65205 11.6901 8.08427C12.3945 8.54267 13.1049 8.54267 13.8092 8.08427C14.4733 7.65205 15.3555 7.25 16.3589 7.25C18.1963 7.25 19.8163 8.19962 20.7853 9.92065C20.891 10.1084 20.9109 10.3324 20.8399 10.5358C20.7689 10.7392 20.6139 10.9022 20.4143 10.9834C19.3732 11.4067 18.6557 12.4049 18.6557 13.5544C18.6557 14.8087 19.5114 15.884 20.7065 16.2263C20.903 16.2826 21.0681 16.4167 21.1634 16.5975C21.2588 16.7783 21.2762 16.9902 21.2116 17.1842C20.7992 18.4234 20.1749 19.5988 19.4333 20.5488C18.7 21.4881 17.8066 22.2641 16.8335 22.6129C15.6815 23.0258 14.598 22.4268 13.9145 21.937C13.6845 21.7721 13.4469 21.6233 13.2252 21.5182C12.9932 21.4083 12.8354 21.3736 12.7497 21.3736C12.6639 21.3736 12.5061 21.4083 12.2742 21.5182C12.0525 21.6233 11.8148 21.7721 11.5848 21.937C10.9013 22.4268 9.81784 23.0258 8.66585 22.6129C7.31055 22.1272 6.08989 20.8062 5.2243 19.2862C4.34595 17.7438 3.75 15.8542 3.75 14.0593C3.75 11.9787 4.30404 10.2824 5.27858 9.09078Z"
        fill="currentColor"
      />
    </svg>
  );
}
