'use client';

import { SubmitHandler, useForm } from 'react-hook-form';

import { useSigninFormConfigContext } from '@/features/authentication/signin/providers/sign-in-form-context-provider';
import { PhoneInfoValue } from '@/features/profile/types';

import { ROUTES } from '@/shared/routes';

import { useRouter } from '@/lib/navigation';

export interface PhoneNumberFormInputs {
  phone: PhoneInfoValue;
}

export const usePhoneNumberForm = () => {
  const router = useRouter();
  const { config, setConfig } = useSigninFormConfigContext();

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    trigger,
    setValue,
    getValues,
  } = useForm<PhoneNumberFormInputs>({
    criteriaMode: 'all',
    reValidateMode: 'onChange',
  });

  const handleSignin: SubmitHandler<PhoneNumberFormInputs> = (data) => {
    const { countryCode, number } = data.phone;
    const phoneNumber: string = `+${countryCode}${number}`;

    setConfig({
      ...config,
      data: {
        username: phoneNumber,
        isSSO: false,
      },
    });

    router.push(ROUTES.SIGN_IN.$PASSCODE_VERIFICATION(ROUTES.SIGN_IN.INDEX));
  };

  return {
    errors,
    control,
    trigger,
    register,
    setValue,
    getValues,
    handleSignin: handleSubmit(handleSignin),
  };
};
