import { isClient } from '@/lib/utils';

import { SSOServiceProvider, SSOServiceProviderName } from '../../types';

import { config } from '@/config';

export class AppleSSO implements SSOServiceProvider {
  name = SSOServiceProviderName.Apple;

  async signIn(redirectUri: string): Promise<void> {
    if (!isClient()) {
      return;
    }

    const url = `https://appleid.apple.com/auth/authorize?client_id=${config.APPLE_OAUTH_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${config.APPLE_OAUTH_SCOPE}&response_type=${config.APPLE_OAUTH_RESPONSE_TYPE}&response_mode=${config.APPLE_OAUTH_RESPONSE_MODE}&state=obyhpe50xds&nonce=beqam8rma04`;

    window.open(url, '_self');
  }
}
