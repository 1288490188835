import { BaseButton } from '../base-button';
import { HTMLCustomVariantsAwareProps, createComponent } from '@/ui/system';
import { ReactNode } from 'react';
import { SocialButtonVariants } from './social-button.cx';

type BaseProps = Omit<
  HTMLCustomVariantsAwareProps<'button', SocialButtonVariants>,
  'layout'
>;

export interface SocialButtonProps extends BaseProps {
  icon?: ReactNode;
}

export interface SocialButtonProps extends BaseProps {}

export const SocialButton = createComponent<SocialButtonProps>(
  ({ icon, ...props }, ref) => {
    return <BaseButton {...props} startIcon={icon} ref={ref} />;
  },
);

SocialButton.displayName = 'SocialButton';
