import { cn } from '@/lib/utils';

export function BaseGoogleLogo({
  color1,
  color2,
  color3,
  color4,
  className,
  ...props
}: React.SVGProps<SVGSVGElement> & {
  color1?: string;
  color2?: string;
  color3?: string;
  color4?: string;
}) {
  return (
    <svg
      {...props}
      fill="none"
      viewBox="0 0 21 20"
      aria-label={props['aria-label'] ?? 'Google Logo'}
      xmlns="http://www.w3.org/2000/svg"
      className={cn('p-[1px]', className)}
    >
      <g clipPath="url(#clip0_4714_4339)">
        <path
          fill={color1}
          d="M20.3052 10.2302C20.3052 9.55044 20.2501 8.86699 20.1325 8.19824H10.7002V12.0491H16.1016C15.8775 13.291 15.1573 14.3897 14.1027 15.0878V17.5864H17.3252C19.2176 15.8448 20.3052 13.2726 20.3052 10.2302Z"
        />
        <path
          fill={color2}
          d="M10.6999 20.0008C13.397 20.0008 15.6714 19.1152 17.3286 17.5867L14.1061 15.088C13.2096 15.698 12.0521 16.0434 10.7036 16.0434C8.09474 16.0434 5.88272 14.2833 5.08904 11.917H1.76367V14.4928C3.46127 17.8696 6.91892 20.0008 10.6999 20.0008Z"
        />
        <path
          fill={color3}
          d="M5.08564 11.9168C4.66676 10.6748 4.66676 9.32995 5.08564 8.08799V5.51221H1.76395C0.345612 8.33785 0.345612 11.6669 1.76395 14.4925L5.08564 11.9168Z"
        />
        <path
          fill={color4}
          d="M10.6999 3.95805C12.1256 3.936 13.5035 4.47247 14.536 5.45722L17.3911 2.60218C15.5833 0.904587 13.1838 -0.0287217 10.6999 0.000673888C6.91892 0.000673888 3.46127 2.13185 1.76367 5.51234L5.08537 8.08812C5.87538 5.71811 8.09107 3.95805 10.6999 3.95805Z"
        />
      </g>
      <defs>
        <clipPath id="clip0_4714_4339">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export function PlainGoogleLogo(props: React.SVGProps<SVGSVGElement>) {
  return (
    <BaseGoogleLogo
      color1="currentColor"
      color2="currentColor"
      color3="currentColor"
      color4="currentColor"
      {...props}
    />
  );
}

const GoogleLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <BaseGoogleLogo
      color1="#4285F4"
      color2="#34A853"
      color3="#FBBC04"
      color4="#EA4335"
      {...props}
    />
  );
};

export default GoogleLogo;
