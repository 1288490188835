import { Flex } from '@/ui/components/flex';
import { SocialButton } from '@/ui/components/social-button';
import { AppleLogo } from '@/ui/svgs/apple-logo';
import GoogleLogo from '@/ui/svgs/google-logo';

export function SsoAccess() {
  return (
    <Flex gapX="units-unit14">
      <SocialButton
        className={{
          root: 'border-neutral-s3 bg-neutral-s2 text-content-body-default font-semibold',
        }}
        variant="outline"
        size="medium"
        aria-label="social-button-google"
        block
        icon={<GoogleLogo />}
      >
        Google
      </SocialButton>
      <SocialButton
        className={{
          root: 'border-neutral-s3 bg-neutral-s2 text-content-body-default font-semibold',
        }}
        variant="outline"
        size="medium"
        aria-label="social-button-apple"
        block
        icon={<AppleLogo />}
      >
        Apple
      </SocialButton>
    </Flex>
  );
}
