'use client';

import {
  PhoneNumberForm,
  PhoneNumberFormProps,
} from '@/features/authentication/signin/components';

import { Column, Flex } from '@/ui/components/flex';
import { Separator } from '@/ui/components/separator';
import { Text } from '@/ui/components/text';

import { SignInWithApple } from './sign-in-with-apple';
import { SignInWithGoogle } from './sign-in-with-google';
import { SsoAccess } from './sso-access';

export function InitiateAuthentication(
  props: Readonly<
    PhoneNumberFormProps & {
      ssoEnabled: boolean;
      flow: 'signin' | 'recover-passcode';
    }
  >,
) {
  const { ssoEnabled, flow, ...restProps } = props;

  return (
    <Column gapY="units-unit24">
      {ssoEnabled && (
        <Flex className="gap-[21px]" direction="column">
          {flow === 'signin' ? (
            <Flex gapX="units-unit14">
              <SignInWithGoogle className={{ root: 'flex-1' }} />
              <SignInWithApple className={{ root: 'flex-1' }} />
            </Flex>
          ) : (
            <SsoAccess />
          )}
          <Separator>
            <Text
              as="span"
              variant="body-small"
              color="content-heading-alternative"
            >
              Or
            </Text>
          </Separator>
        </Flex>
      )}
      <PhoneNumberForm {...restProps} />
    </Column>
  );
}
