import { classes, defineVariants } from '@/ui/system';

export type SeparatorVariants = typeof separatorVariants;

export const separatorVariants = defineVariants({
  variants: {
    direction: {
      row: 'h-[1px] w-full',
      column: 'w-[1px] h-full',
    },
    color: classes.textColor,
  },
  defaultVariants: {
    direction: 'row',
    color: 'ui-border-alternative',
  },
});
