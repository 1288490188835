import { usePathname } from 'next/navigation';
import { useEffect } from 'react';
import { toast } from 'sonner';

import { useHttpQuery } from '@/shared/hooks';

import { identityApi } from '@/lib/api';
import { useSearchParams } from '@/lib/navigation';

import { SSOServiceProviderName } from '../../types';
import { AppleSSO } from './apple-sso';

import { config } from '@/config';

export const appleSSO = new AppleSSO();

export function useAppleSSO() {
  const pathname = usePathname();
  const redirectUri = `${config.BASE_URL}${pathname}`;

  const { searchParams } = useSearchParams();
  const code = searchParams.get('code');

  const { data, error, isLoading } = useHttpQuery<
    {
      clientId: string;
      redirectUri: string;
      code: string | null;
      providerType: SSOServiceProviderName;
    },
    { idToken: string }
  >({
    retry: false,
    staleTime: 0,
    method: 'POST',
    enabled: !!code,
    api: identityApi,
    url: '/social-exchanges',
    queryKey: ['apple-sso', code],
    data: {
      code,
      redirectUri,
      providerType: appleSSO.name,
      clientId: config.APPLE_OAUTH_CLIENT_ID,
    },
  });

  // Effect: Show error message
  useEffect(() => {
    if (!error) return;

    toast.error('Unable to use Apple SSO right now. Please try again later.');
  }, [error]);

  return {
    appleSSO,
    isLoading,
    redirectUri,
    idToken: data?.idToken,
  };
}
