import { SocialButton, SocialButtonProps } from '@/ui/components/social-button';
import GoogleLogo from '@/ui/svgs/google-logo';

import { cn } from '@/lib/utils';

export type GoogleButtonProps = SocialButtonProps;

export function GoogleButton({
  className,
  ...props
}: Readonly<GoogleButtonProps>) {
  return (
    <SocialButton
      block
      size="medium"
      variant="outline"
      icon={<GoogleLogo />}
      className={{
        ...className,
        root: cn(
          'border-neutral-s3 bg-grey/L2 text-content-heading-default',
          className?.root,
        ),
      }}
      {...props}
    />
  );
}
