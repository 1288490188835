import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';

import { GoogleSSO } from './google-sso';

import { config } from '@/config';

export const googleSSO = new GoogleSSO();

export function useGoogleSSO() {
  const pathname = usePathname();
  const redirectUri = `${config.BASE_URL}${pathname}`;
  const [idToken, setIdToken] = useState<string | null>(null);

  // Effect: Get idToken from URL hash
  useEffect(() => {
    const hash = window.location.hash;
    setIdToken(googleSSO.getIdTokenFromHash(hash));
  }, []);

  return {
    idToken,
    googleSSO,
    redirectUri,
  };
}
