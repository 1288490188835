import { isClient, log } from '@/lib/utils';

import { SSOServiceProvider, SSOServiceProviderName } from '../../types';

import { config } from '@/config';

export class GoogleSSO implements SSOServiceProvider {
  name = SSOServiceProviderName.Google;

  async signIn(redirectUri: string): Promise<void> {
    if (!isClient()) {
      return;
    }

    const url = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${config.GOOGLE_OAUTH_CLIENT_ID}&redirect_uri=${redirectUri}&scope=${encodeURIComponent(config.GOOGLE_OAUTH_SCOPE)}&response_type=${config.GOOGLE_OAUTH_RESPONSE_TYPE}&response_mode=${config.GOOGLE_OAUTH_RESPONSE_MODE}&state=obyhpe50xds&nonce=beqam8rma04`;

    window.open(url, '_self');
  }

  getIdTokenFromHash(hash: string): string | null {
    if (!hash) {
      return null;
    }

    const hashWithoutHash = hash.replace(/^#/, '');
    const idToken = new URLSearchParams(hashWithoutHash).get('id_token');

    if (!idToken) {
      log('No id_token found in hash');
      return null;
    }

    return idToken;
  }
}
