import { useEffect } from 'react';

import { useSignInForm } from '@/features/authentication/signin/hooks/use-sign-in-form';
import { useSigninFormConfigContext } from '@/features/authentication/signin/providers/sign-in-form-context-provider';

import { PageLoader } from '@/ui/components/page';
import {
  AppleButton,
  AppleButtonProps,
} from '@/ui/components/social-button/variants';

import { useAppleSSO } from '@/lib/sso-service/providers/apple';
import { SSOServiceProviderName } from '@/lib/sso-service/types';

export function SignInWithApple(props: Readonly<AppleButtonProps>) {
  const { idToken, appleSSO, isLoading, redirectUri } = useAppleSSO();
  const { config, setConfig } = useSigninFormConfigContext();
  const { handleSignIn } = useSignInForm({});

  function onClick() {
    appleSSO.signIn(redirectUri);
  }

  useEffect(() => {
    if (!idToken) return;

    setConfig({
      ...config,
      data: {
        ...config.data,
        isSSO: true,
        idToken,
        socialProvider: SSOServiceProviderName.Apple,
      },
    });
  }, [idToken]);

  useEffect(() => {
    if (
      !(
        config.data.idToken &&
        config.data.socialProvider === SSOServiceProviderName.Apple
      )
    ) {
      return;
    }

    handleSignIn();
  }, [config.data.idToken, config.data.socialProvider]);

  return (
    <>
      {isLoading && <PageLoader />}
      <AppleButton
        className={{
          root: 'border-neutral-s3 bg-neutral-s2 text-content-body-default font-semibold',
        }}
        {...props}
        onClick={onClick}
      >
        Apple
      </AppleButton>
    </>
  );
}
