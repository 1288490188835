import { SocialButton, SocialButtonProps } from '@/ui/components/social-button';
import { AppleLogo } from '@/ui/svgs/apple-logo';

import { cn } from '@/lib/utils';

export type AppleButtonProps = SocialButtonProps;

export function AppleButton({
  className,
  ...props
}: Readonly<AppleButtonProps>) {
  return (
    <SocialButton
      block
      size="medium"
      variant="outline"
      icon={<AppleLogo />}
      className={{
        ...className,
        root: cn(
          'border-neutral-s3 bg-grey/L2 text-content-heading-default',
          className?.root,
        ),
      }}
      {...props}
    />
  );
}
